// src/app/app-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './external/page-not-found/page-not-found.component';
import { QuoteNotFoundComponent } from './external/quote-not-found/quote-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/404', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule) },
  { path: 'quote-not-found', component: QuoteNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
  // Add more routes if needed
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
