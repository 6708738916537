import { Component } from '@angular/core';

@Component({
  selector: 'app-quote-not-found',
  templateUrl: './quote-not-found.component.html',
  styleUrls: ['./quote-not-found.component.css']
})
export class QuoteNotFoundComponent {

}
