import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from "@sentry/angular";
Sentry.init({
  dsn: "https://543c7a4a12a056cfb1f4b2d124fc29c8@o1166453.ingest.us.sentry.io/4509038915354624"
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
