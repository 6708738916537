<section class="section login-section">
    <div class="template-wrap"
        style="width: 100%;max-width: 580px; margin: 30px auto; font-family: 'Poppins', sans-serif;box-shadow: 0 0px 19px #eee;">
        <div class="template-heaader" style="text-align: center;padding: 40px 20px 20px 20px;">
            <h1 style="color:#ccc">404</h1>
            <h2 style="color:#ccc">Quote Not Found</h2>
            <br/><br/>
            <!-- <button routerLink="/" class="btn btn-primary">Home</button> -->
        </div>
    </div>
</section>